import React, { useRef, useEffect, forwardRef, useContext } from "react";
import globalStyles from "../../styles/globalStyles.module.scss";
import styles from "./canvas.module.scss";
import mgl1 from "../../ressources/images/mgl1.png";
import mgl2 from "../../ressources/images/mgl2.png";
import mgl3 from "../../ressources/images/mgl3.png";
import mgl4 from "../../ressources/images/mgl4.png";
import mgl5 from "../../ressources/images/mgl5.png";
import mgl6 from "../../ressources/images/mgl6.png";
import mgl7 from "../../ressources/images/mgl7.png";
import mgl8 from "../../ressources/images/mgl8.png";
import mgl9 from "../../ressources/images/mgl9.png";
import mgl10 from "../../ressources/images/mgl10.png";
import mgl11 from "../../ressources/images/mgl11.png";
import mgl12 from "../../ressources/images/mgl12.png";
import mglg1 from "../../ressources/images/mglg1.png";
import mglg2 from "../../ressources/images/mglg2.png";
import mgls1 from "../../ressources/images/mgls1.png";
import mgls2 from "../../ressources/images/mgls2.png";
import pipe1 from "../../ressources/images/pipe1.png";
import mgl_21 from "../../ressources/images/mogglesecondwave-012.png"
import mgl_22 from "../../ressources/images/mogglesecondwave-013.png"
import mgl_23 from "../../ressources/images/mogglesecondwave-014.png"
import mgl_24 from "../../ressources/images/mogglesecondwave-015.png"
import mgl_25 from "../../ressources/images/mogglesecondwave-016.png"
import mgl_26 from "../../ressources/images/mogglesecondwave-017.png"
import mgl_27 from "../../ressources/images/mogglesecondwave-018.png"
import mgl_28 from "../../ressources/images/mogglesecondwave-019.png"
import mgl_29 from "../../ressources/images/mogglesecondwave-020.png"
import mgl_30 from "../../ressources/images/mogglesecondwave-021.png"
import mgl_31 from "../../ressources/images/mogglesecondwave-022.png"
import mgl_32 from "../../ressources/images/mogglesecondwave-023.png"
// LAST UPDATE 29/09 

import mgl31 from "../../ressources/images/moglast1.png";
import mgl32 from "../../ressources/images/moglast2.png";
import mgl33 from "../../ressources/images/moglast3.png";
import mgl34 from "../../ressources/images/moglast4.png";
import mgl35 from "../../ressources/images/moglast5.png";
import mgl36 from "../../ressources/images/moglast6.png";
import mgl37 from "../../ressources/images/moglast7.png";
import mgl38 from "../../ressources/images/moglast8.png";
import mgl39 from "../../ressources/images/moglast9.png";
import mgl310 from "../../ressources/images/moglast10.png";
import mgl311 from "../../ressources/images/moglast11.png";
import mgl312 from "../../ressources/images/moglast12.png";
import mgl313 from "../../ressources/images/moglast13.png";
import mgl314 from "../../ressources/images/moglast14.png";
import mgl315 from "../../ressources/images/moglast15.png";
import mgl316 from "../../ressources/images/moglast16.png";
import mgl317 from "../../ressources/images/moglast17.png";
import mgl318 from "../../ressources/images/moglast18.png";
import mgl319 from "../../ressources/images/moglast19.png";
import mgl320 from "../../ressources/images/moglast20.png";
//LAST UPDATE 13/10/2023
import mgl_33 from "../../ressources/images/mog_my_pfp_mfer.png"


import resetSvg from "../../ressources/images/reset.svg";
import { ReactComponent as ResetSvg } from "../../ressources/images/reset.svg";
import GridContainer from "../wrapper/gridContainer";
import Sticker from "../sticker/sticker";
import StickerGenerator from "../stickerGenerator/stickerGenerator";
import useCanvasInteractions from "./hooks/useCanvasInteraction";
import Trash from "../trash/trash";
import saveCompositeImage from "./utils/saveCompositeImg";
import ViewportContext from "../../context/viewportCtxt.js";

const Canvas = forwardRef(
  ({ uploadedImage, imgCanvasRef, saveImage, setSaveImage, imageLoaded }) => {
    const canvasRef = useRef(null);
    const imgCanvasWrapperRef = useRef(null);
    const headerRef = useRef(null);
    const viewport = useContext(ViewportContext);
    const {
      stickers,
      stickersRefs,
      activeIndex,
      handleInteractionStart,
      handleInteractionMove,
      handleInteractionEnd,
      handleActiveSticker,
      setInitialPosition,
      handleGenerateSticker,
      trashRef,
      inTrash,
      handleClickOutside,
      handleOnClickDelete,
      handleReset,
    } = useCanvasInteractions(canvasRef);

    useEffect(() => {
      if (!imageLoaded || !uploadedImage) return;

      const aspectRatio = uploadedImage.width / uploadedImage.height;

      let width, height;

      //add logic to handle canvas max height // image spills out of canvas if width is too big
      if (aspectRatio < 1) {
        height = canvasRef.current.offsetHeight;
        width = height * aspectRatio;
        if (canvasRef.current.offsetwidth < width) {
          width = canvasRef.current.offsetWidth;
          height = width / aspectRatio;
        }
      } else {
        width = canvasRef.current.offsetWidth;
        height = width / aspectRatio;
        if (canvasRef.current.offsetHeight < height) {
          height = canvasRef.current.offsetHeight;
          width = height * aspectRatio;
        }
      }

      if (saveImage) {
        saveCompositeImage(
          uploadedImage,
          stickers,
          {
            x:
              imgCanvasRef.current.getBoundingClientRect().left -
              canvasRef.current.getBoundingClientRect().left,
            y:
              imgCanvasRef.current.getBoundingClientRect().top -
              canvasRef.current.getBoundingClientRect().top,
          },
          width,
          globalStyles.stickerSize
        );
        setSaveImage(false);
      }
      imgCanvasRef.current.width = width;
      imgCanvasRef.current.height = height;
      imgCanvasRef.current.style.width = width + "px";
      imgCanvasRef.current.style.height = height + "px";

      imgCanvasRef.current
        .getContext("2d")
        .drawImage(uploadedImage, 0, 0, width, height);

      return () => {};
    }, [
      uploadedImage,
      imgCanvasRef,
      saveImage,
      setSaveImage,
      stickers,
      imageLoaded,
    ]);

    useEffect(() => {
      window.addEventListener("click", handleClickOutside);
      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, [handleClickOutside, viewport]);

    return (
      <div
        className={styles.root}
        ref={canvasRef}
        onMouseDown={handleInteractionStart}
        onMouseUp={handleInteractionEnd}
        onTouchStart={handleInteractionStart}
        onTouchEnd={handleInteractionEnd}
        onTouchMove={handleInteractionMove}
      >
        <div className={styles.header} ref={headerRef}>
          <div className={styles["sticker-generator-wrapper"]}>
            <GridContainer iconImg={mgl1} headerRef={headerRef}>
              <StickerGenerator
                img={mgl1}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl2}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl3}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl4}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl5}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl6}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl7}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl8}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl9}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl10}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl11}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl12}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgls1}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgls2}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_21}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_22}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_23}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_24}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_25}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_26}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_27}
                handleGenerateSticker={handleGenerateSticker}
              /><StickerGenerator
                img={mgl_28}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_29}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_30}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_31}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mgl_32}
                handleGenerateSticker={handleGenerateSticker}
              />
            </GridContainer>
            <GridContainer iconImg={mglg1} headerRef={headerRef}>
              <StickerGenerator
                img={mglg1}
                handleGenerateSticker={handleGenerateSticker}
              />
              <StickerGenerator
                img={mglg2}
                handleGenerateSticker={handleGenerateSticker}
              />
            </GridContainer>
            <GridContainer iconImg={mgl31} headerRef={headerRef}>
            <StickerGenerator
    img={mgl31}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl32}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl33}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl34}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl35}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl36}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl37}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl38}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl39}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl310}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl311}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl312}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl313}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl314}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl315}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl316}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl317}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl318}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl319}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl320}
    handleGenerateSticker={handleGenerateSticker}
/>
<StickerGenerator
    img={mgl_33}
    handleGenerateSticker={handleGenerateSticker}
/>

            </GridContainer>
          </div>
          <div className={styles["cancel-buttons"]}>
            <div className={styles["reset"]} onClick={handleReset}>
              <ResetSvg />
              {/* <img src={resetSvg} alt="" /> */}
            </div>

            <Trash
              ref={trashRef}
              inTrash={inTrash}
              handleOnClickDelete={handleOnClickDelete}
            />
          </div>
        </div>

        <div className={styles["img-canvas-wrapper"]} ref={imgCanvasWrapperRef}>
          <canvas ref={imgCanvasRef} />
        </div>

        {stickers.map((sticker, index) => {
          return (
            <Sticker
              img={sticker.img}
              key={sticker.key}
              inTrash={inTrash}
              position={sticker.position}
              setInitialPosition={setInitialPosition}
              ref={(node) => {
                stickersRefs.current[index] = node;
              }}
              scale={sticker.scale}
              rotation={sticker.rotation}
              activeIndex={activeIndex}
              index={index}
              handleActiveSticker={(event) => {
                handleActiveSticker(event, index);
              }}
            />
          );
        })}
      </div>
    );
  }
);

export default Canvas;
